import React, { Component } from 'react';
import 'typeface-roboto';
import metmalaysia from '../../images/malaysiaweatherforecast_img.png';
import mytvmovie from '../../images/mytvmovie_img.png';
import dbsqlxmlconverter_img from '../../images/dbsqlxmlconverter_img.png';
import nchatApp from '../../images/nchatapp_img.png';
import jiiicasImg from '../../images/jiiicas_img.png';
import laundry_service from '../../images/laundry_service.png';
import theme_park from '../../images/theme_park.png';
import homestay from '../../images/homestay.png';
import jamu_img from '../../images/JamuWeb/jamu_img.png';
import pet_shop_img from '../../images/PetShop/pet_shop.png';
import { CustomFooter } from '../CustomFooter';
import { Card, CardActionArea, CardContent, CardMedia, Container, Grid, Typography } from '@mui/material';

export class OurWork extends Component {
    static displayName = OurWork.name;
    constructor(props) {

        super(props);
        this.state = {
            aboutUsContentP1: "Nami Studio MY is founded in 2022 by a Malaysian Software Engineer who has passion to working and learning on software application in our free time. We like to improve our ability in Software Programming .We are seeking for new experience, challenges and technologies while enjoy continuous learning."

        }
    }
    render() {
        return (
            <div>
                <Container>
                    <Grid container spacing={4} sx={{ mt: 10, mb: 5 }}>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/nami-met-malaysia">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={metmalaysia}
                                            alt="metmalaysia"
                                            sx={{ backgroundColor: '#1F5D6C' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                NAMI MET Malaysia
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/my-tv-movie">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={mytvmovie}
                                            alt="mytvmovie"
                                            sx={{ backgroundColor: '#F6E71C' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                MY TV Movie
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/dbsqlxml-converter">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={dbsqlxmlconverter_img}
                                            alt="dbsqlxmlconverter_img"
                                            sx={{ backgroundColor: '#B20930' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                DBSQLXML Converter
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/nchat-app">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={nchatApp}
                                            alt="nchatApp"
                                            sx={{ backgroundColor: '#9E58FA' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                NChatApp
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/jiiicas">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={jiiicasImg}
                                            alt="jiiicasImg"
                                            sx={{ backgroundColor: '#29667D' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                JIIICas Website
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/jamu">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={jamu_img}
                                            alt="jamu_img"
                                            sx={{ backgroundColor: '#D5FE90' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Jamu Website 
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/laundry-service">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={laundry_service}
                                            alt="laundry_service"
                                            sx={{ backgroundColor: '#3BD0D6' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Laundry Service
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/theme-park">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={theme_park}
                                            alt="theme-park"
                                            sx={{ backgroundColor: '#CA402A' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Theme Park
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/homestay">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={homestay}
                                            alt="homestay"
                                            sx={{ backgroundColor: '#0B2B26' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Homestay
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <a href="#/our-work/pet-shop">
                                <Card sx={{ maxWidth: 400 }}>
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            height="260"
                                            image={pet_shop_img}
                                            alt="pet_shop_img"
                                            sx={{ backgroundColor: '#F8D046' }}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="div">
                                                Pet Shop
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </a>
                        </Grid>
                    </Grid>
                </Container>
                <CustomFooter />
            </div>
        );
    }
}
