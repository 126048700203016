import React, { Component } from 'react';
import theme_park from '../../images/PetShop/pet_shop.png';
import 'typeface-roboto';
import { CustomFooter } from '../CustomFooter';
import ss1 from '../../images/PetShop/1.png';
import ss2 from '../../images/PetShop/2.png';
import ss3 from '../../images/PetShop/3.png';
import ss4 from '../../images/PetShop/4.png';
import ss5 from '../../images/PetShop/5.png';

export class PetShop extends Component {
    static displayName = PetShop.name;
    constructor(props) {

        super(props);
        this.state = {
        }
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <div class="section-full">
                    <div class="paddingOurWorkDetail">
                        <section>
                            <img class="scaleOurWorkImage" src={theme_park} alt="theme_park" />
                            <h1 class="roboto-medium" style={{ color: 'white' }}>Pet Shop Template</h1>
                        </section>

                        <table id="tableWithoutHeader">
                            <tr>
                                <td>Technology used:</td>
                                <td>Vite React</td>
                            </tr>
                            <tr>
                                <td>Description:</td>
                                <td>
                                    Template for Pet Shop
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" style={{ textAlign: 'center' }}> <a class="ourWorkLink" href="https://pet-shop-template.namistudiomy.com/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>
                                    Link to the Website
                                </a></td>
                            </tr>
                        </table>
                    </div>

                    <div class="paddingOurWorkScreenShort">
                        <h1 class="roboto-bold" style={{ textAlign: 'center' }}>SCREEN <font color="#FF001F">SHORT</font></h1>
                        <hr style={{ width: '10%', margin: 'auto', color: 'white' }}></hr>

                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss1} alt="ss1" /></td>
                                        <div>&nbsp;</div>
                                    </tr>
                                </div>
                            </div>

                            <div>&nbsp;</div>

                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss2} alt="ss2" /></td>
                                        <div>&nbsp;</div>
                                    </tr>
                                </div>
                            </div>
                        </div>

                        <div>&nbsp;</div>

                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss3} alt="ss3" /></td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss4} alt="ss4" /></td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>
                        <div class="row" style={{ placeContent: 'center' }} >
                            <div class="col-12 col-md-6" style={{ alignSelf: 'center' }}>
                                <div>
                                    <tr>
                                        <td> <img class="scaleOurWorkImage" src={ss5} alt="ss5" /></td>
                                    </tr>
                                </div>
                            </div>
                        </div>
                        <div>&nbsp;</div>

                    </div>

                    <CustomFooter />
                </div>
            </div>
        );
    }
}
